import NOTIFICATIONS from "../../constants/notifications";
import { withGuard, tryCatch } from "../../helpers/middleware";
import { setElementVisibility } from "../../helpers/dom-manipulation";
import { debounce } from "../../helpers/time";
import createNotificationsApi from "./api";
import { swiperOptionsVertical } from "../lux-swiper/options";
import {
    migrateSwiper,
    getSlideDataId,
    getNumberOfLoadedSlides,
    getMostRecentSlideId,
    getFullyVisibleSlides,
} from "../lux-swiper/helpers";

export class LuxNotifications {
    constructor(swiperId, csrf) {
        // Initialize properties
        this.swiperId = swiperId;
        this.api = createNotificationsApi(csrf);
        this.notificationsSlider = null;
        this._initialNotifications = [];
        this.readTimers = {};
        this.notificationsCount = -1;
        this.notificationsSlideProgress = -1;
        this.translate = 0;

        // DOM elements
        const dropdown = document.querySelector(NOTIFICATIONS.dropdown);
        const unreadAmount = document.querySelector(NOTIFICATIONS.unreadAmount);

        const swiperWrapper = document.querySelector(
            NOTIFICATIONS.swiperWrapper,
        );

        const markAllAsRead = document.getElementById(
            NOTIFICATIONS.markAllAsRead,
        );

        if (!dropdown || !swiperWrapper || !markAllAsRead) {
            return;
        }

        // Preload initial notifications
        const preloadPromise = tryCatch(async () => {
            await this.preloadNotificationsData();
        })();

        dropdown.addEventListener("click", async () => {
            await preloadPromise;
            await tryCatch(
                async () =>
                    await this.initNotificationsSlider(
                        swiperWrapper,
                        unreadAmount,
                    ),
            )();
        });

        markAllAsRead.addEventListener(
            "click",
            tryCatch(async () =>
                this.handleMarkAllNotifications(markAllAsRead, unreadAmount),
            ),
        );
    }

    get reachedEndOfAllNotifications() {
        return this.notificationsCount <= this.notificationsSlideProgress;
    }

    async preloadNotificationsData() {
        const { notifications, notificationsCount } =
            await this.api.fetchInitialNotifications();

        this._initialNotifications = notifications;
        this.notificationsCount = notificationsCount;
    }

    async initNotificationsSlider(swiperWrapper, unreadAmount) {
        if (!this.notificationsSlider) {
            this.notificationsSlider = new swiperScroll(
                this.swiperId,
                swiperOptionsVertical,
            );

            await withGuard(
                async () =>
                    this.handleLoading(
                        swiperWrapper,
                        this._initialNotifications.length,
                    ),
                () => setElementVisibility(NOTIFICATIONS.loader),
            )();

            if (!this._initialNotifications.length) return;

            this.notificationsSlideProgress = getNumberOfLoadedSlides(
                this.notificationsSlider,
            );

            this.notificationsSlider._swiper.on(
                "reachEnd",
                withGuard(
                    () => !this.reachedEndOfAllNotifications,
                    tryCatch(async () => this.handleReachEnd(swiperWrapper)),
                ),
            );

            this.notificationsSlider._swiper.on(
                "setTranslate",
                debounce(
                    tryCatch(async () => this.handleSetTranslate(unreadAmount)),
                ),
            );
        } else {
            this.notificationsSlider.updateTranslate(this.translate);
        }
    }

    async handleLoading(swiperWrapper, notifications) {
        if (!notifications) {
            setElementVisibility(NOTIFICATIONS.empty, true);
        } else {
            migrateSwiper(this._initialNotifications, swiperWrapper);
            this.notificationsSlider.updateTranslate(this.translate);
        }
        return;
    }

    async handleReachEnd(swiperWrapper) {
        const uid = getMostRecentSlideId(this.notificationsSlider);
        const { notifications } = await this.api.fetchMoreNotifications(uid);
        migrateSwiper(notifications, swiperWrapper);
        this.notificationsSlider.update();

        this.notificationsSlideProgress = getNumberOfLoadedSlides(
            this.notificationsSlider,
        );
    }

    async handleSetTranslate(unreadAmount) {
        this.translate = this.notificationsSlider.getCurrentTranslate();

        const slidesToProcess = getFullyVisibleSlides(
            [NOTIFICATIONS.fullyVisible, NOTIFICATIONS.visible],
            this.reachedEndOfAllNotifications,
        );

        slidesToProcess.forEach(
            tryCatch((slide) => this.processSlideAsRead(slide, unreadAmount)),
        );
    }

    async processSlideAsRead(slide, unreadAmount) {
        const uid = getSlideDataId(slide.parentElement);
        if (!uid) throw new Error("UID is null or undefined");

        const { success, unreadCountText } =
            await this.api.fetchMarkNotificationAsRead(uid);

        if (success) {
            slide.classList.remove("unread");
            this.updateUnreadAmount(unreadAmount, unreadCountText);
        }
    }

    updateUnreadAmount(unreadAmount, unreadCountText) {
        if (!unreadAmount) return;

        if (unreadCountText < 1) {
            unreadAmount.hidden = true;
        } else {
            unreadAmount.innerHTML = unreadCountText;
        }
    }

    async handleMarkAllNotifications(markAllAsRead, unreadAmount) {
        const { success } = await this.api.fetchMarkAllNotificationsAsRead();

        if (success) {
            markAllAsRead
                .closest(".lux-dropdown-wrapper")
                .classList.remove("active");

            document
                .querySelectorAll(".lux-notification-slide")
                .forEach((slide) => slide.classList.remove("unread"));

            unreadAmount && (unreadAmount.hidden = true);
        }
    }
}
