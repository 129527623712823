export const swiperOptionsVertical = {
    direction: "vertical",
    slidesPerView: "auto",
    freeMode: true,
    watchSlidesProgress: true,
    scrollbar: {
        el: ".swiper-scrollbar",
        draggable: true,
    },
    mousewheel: {
        forceToAxis: true,
    },
};

const swiperOptions = {
    getSliderOptions(slider) {
        return {
            perView: parseInt(slider.getAttribute("data-per-view")) || 1,
            gap: parseInt(slider.getAttribute("data-gap")) || 24,
            centerSlides: slider.getAttribute("data-center-slides"),
            centerSlidesBounds: slider.getAttribute("data-center-bounds"),
            breakpoints: JSON.parse(
                slider.getAttribute("data-breakpoints") || "[]",
            ),
            initialSlide:
                parseInt(slider.getAttribute("data-initial-slide")) || 1,
            focusCenter: slider.getAttribute("data-focus-center"),
            loop: slider.getAttribute("data-loop"),
            autoplay: slider.getAttribute("data-autoplay"),
            autoplayDelay:
                parseInt(slider.getAttribute("data-autoplay-delay")) || 10000,
        };
    },
    getScrollOptions(slider) {
        return {
            centerSlides: slider.getAttribute("data-center-slides"),
            initialSlide: slider.getAttribute("data-initial-slide"),
        };
    },
};

window.swiperOptions = swiperOptions;
